// Packages
import React from "react";
import { graphql } from "gatsby";

// Types
import type { SiteMetadata, Location } from "~types/Gatsby";
import type { Product } from "~types/Shopify";
import type { IGatsbyImageData } from "gatsby-plugin-image";

// Components
import Card from "~components/cards/Card";
import Grid from "~components/common/Grid";
import Container from "~components/common/Container";
import PageHeader from "~components/sections/PageHeader";
import Layout from "~components/layouts/Layout";
interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	products: {
		nodes: Product[];
		distinct: string[];
	};
	imageBusiness: IGatsbyImageData;
	imageSport: IGatsbyImageData;
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	return (
		<Layout
			location={location}
			title="Coaching"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<PageHeader
				subtitle="Coaching"
				title="Unsere Coachingangebote"
				description="Mit unseren Coachingangeboten kannst Du gezielt neue Techniken lernen und Deine Talente stärken."
			/>
			<Container className="space-y-8">
				<Grid cols={2}>
					<Card
						title="Business Coaching für Unternehmer & Selbstständige"
						to="/coaching/business/"
						imageData={data.imageBusiness}
						description="Transformiere dein Business mit maßgeschneidertem 1:1 Coaching"
						buttonText="Zum Business Coaching"
						alt="Business Coaching für Unternehmer & Selbstständige"
					/>
					<Card
						title="Sport Coaching"
						to="/coaching/sport/"
						imageData={data.imageSport}
						description="Sport Coaching für Athleten auf Höchstleistungsniveau"
						buttonText="Zum Sport Coaching"
						alt="Sport Coaching"
					/>
				</Grid>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		imageBusiness: file(relativePath: { eq: "pages/coaching/business-coaching-tile.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
		imageSport: file(relativePath: { eq: "pages/coaching/sport-coaching-tile.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;
